@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,700;1,200;1,400;1,700&display=swap');

$link-color: #EA456E;

* {
  margin: 0;
  padding: 0;
}

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}

.loader {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: #000;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  font-size: 14px;
  background-color: #121020;
  font-family: 'Poppins', sans-serif;
  font-weight: 400; // 200, 400, 700
  opacity: 0;
}

::selection {
  background-color: $link-color;
  color: #fff;
}

.container {
  width: 1200px;
  margin: auto;
  
  @include for-phone-only {
    width: auto;
    overflow: hidden;  
  }
}

header {
  padding: 60px 0;
  display: flex;
  justify-content: flex-end;

  @include for-phone-only {
    padding: 30px 0;
    // padding-bottom: 0px;
    background-color: #121020;
    position: fixed;
    width: 100%;
    z-index: 2;
  }
}

.home {
  @include for-phone-only {
    padding-top: 100px;
  }
}

.p {
  @include for-phone-only {
    padding-top: 100px;
  }
}

.arrow-tilt {
  transform: rotate(-45deg);
  position: relative;
  top: 2px;
  margin-left: 3px;
}

nav {
  
  ul {
    float: right;
    display: flex;

    @include for-phone-only {
      float: auto;
    }
    
    li {
      text-transform: uppercase;
      list-style: none;
      color: #fff;
      margin-right: 30px;
      font-weight: 400;

      @include for-phone-only {
        margin-right: 10px;
      }

      a {
        text-decoration: none;
        color: #fff;
        transition: .3s;
        cursor: pointer;
        padding: 15px;
        letter-spacing: 0.5px;
        
        &.active {
          color: $link-color;
          // font-weight: 700;
        }
      
        &:hover {
          color: $link-color;
        }
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.hero-section {
  display: flex;
  margin-top: 100px;

  @include for-phone-only {
    flex-direction: column-reverse;
    margin-top: 0px;
  }
  
  .content {
     width: 660px;
     color: #fff;

     @include for-phone-only {
       width: 100%;
     }

     .line-wrap {
      overflow: hidden;
      height: 85px;

      @include for-phone-only {
        height: auto;
      }

     }

     h1 {
      font-size: 56px;
      font-weight: 700;

      @include for-phone-only {
        font-size: 36px;
        margin-top: 10px;
        // text-align: left;
        padding: 0 24px;
      }
    }

    .paragraph-content {
      font-size: 36px;
      font-weight: 200;
      line-height: 61px;
      margin-bottom: 20px;

      @include for-phone-only {
        font-size: 18px;
        line-height: 18px;
        padding: 12px 24px;
        margin-bottom: 0;
      }

      .line-wrap-p {
        overflow: hidden;
        height: 60px;
        
        @include for-phone-only {
          height: 20px;
          margin-bottom: 10px;
          
          &:last-child {
            margin-bottom: 0;
          }
        }

        span {
          display: block;
          position: relative;
        }
      }
    }

    .line-wrap-link {
      overflow: hidden;
      height: 57px;

      @include for-phone-only {
        height: 20px;
        font-size: 14px;
        padding: 0 24px;
      }
    }

    a {
      text-decoration: none;
      display: block;
      color: $link-color;
      font-size: 18px;
      padding: 15px 0;
      transition: background-color 0.3s;

      @include for-phone-only {
        padding: 0;
        font-size: 14px;
      }

      &:hover {
        color: #CA0A3A;

        .icon {
          left: 10px;

          svg path {
            stroke: #CA0A3A
          }
        }
      }

      .icon {
        position: relative;
        left: 4px;
        top: 0px;
        width: 20px;
        height: 20px;
        display: inline-block;
        overflow: hidden;
        transition: 0.3s;

        @include for-phone-only {
          top: 6px;
        }
      }
    }
  }

  .image-container {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    position: relative;

    @include for-phone-only {
      justify-content: start;
    }

    .sign-container {
      position: absolute;
      bottom: -190px;
      right: -120px;
      transform: rotate(-15deg);
      z-index: 4;
    }
    
    .image {
      width: 372px;
      height: 372px;
      border-radius: 50%;
      // background-color: #fff;
      position: relative;

      @include for-phone-only {
        width: 150px;
        height: 150px;
        margin: 24px;
        margin-top: 40px;
        border-radius: 4px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;

        @include for-phone-only {
          border-radius: 4px;
        }
      }
      
      &:before {
        content: '';
        position: absolute;
        width: 332px;
        height: 332px;
        left: 10px;
        top: 10px;
        background-color: #FF0073;
        border-radius: 100%;
        box-shadow: 27px 25px 37px 15px #ff0073;
        z-index: -1;

        @include for-phone-only {
          display: none;
        }
      }
    }
  }
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px 2px  #FF0073;
  }
  to {
    box-shadow: 0 0 70px 5px  #FF0073;
  }
}

.brands-container {
  display: flex;
  justify-content: center;
  margin-top: 165px;

  @include for-phone-only {
    flex-wrap: wrap;
    margin-top: 46px;
  }

  .brands {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include for-phone-only {
      padding: 0 20px;
      padding-bottom: 24px;
      border-bottom: 1px solid #424242;
    }
  }
  
  h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #5F5D68;
    margin-bottom: 50px;

    @include for-phone-only {
      margin-bottom: 0;
      font-size: 14px;
    }
  }

  .logo-container {
    display: block;

    @include for-phone-only {
      display: none;
    }

  }

  .logo-container-mobile {
    display: none;

    @include for-phone-only {
      display: block;
      
      ul {
        display: flex;
        list-style: none;
        justify-content: space-between;
        width: 100vw;
        padding: 24px;
        box-sizing: border-box;
        align-items: center;
        
        a {
          display: block;
        }
      }
    }
  }

  ul.logos {
    display: flex;
    list-style: none;

    @include for-phone-only {
      flex-wrap: wrap;
      justify-content: center;
    }
    
    li {
      margin-right: 42px;
      transition: 0.3s;

      @include for-phone-only {
        margin-right: 0px;
        gap: 20px;
      }

      &:hover {
        transform: scale(1.1);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.work-container {
  margin-top: 165px;

  @include for-phone-only {
    margin-top: 50px;
  }

  h3 {
    font-size: 56px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 64px;

    @include for-phone-only {
      font-size: 36px;
      text-align: left;
      margin-bottom: 10px;
      line-height: 42px;
      padding: 0 24px;
    }
  }

  ul {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 8%;

    @include for-phone-only {
      gap: 0px;
      flex-direction: column;
      flex-wrap: unset;
    }

    li {

      a {
        color: inherit;
        display: block;
        text-decoration: none;
        transition: 0.3s;

        * {
          transition: 0.3s;
        }

        &:hover {
          animation: none;
          transition: 0.3s;
          transform: scale(1.01);

          // .image {
          .with-video {

            img {
              display: none;
            }
  
            video {
              display: block;
            }

          }

          span.link {
            color: #CA0A3A;
          }

          .icon {
            left: 10px;
          }
        }
      }

      width: 46%;
      display: block;
      // padding-right: 20px;
      box-sizing: border-box;
      margin-bottom: 65px;

      @include for-phone-only {
        width: 100%;
        padding: 24px;
        margin-bottom: 0px;
      }

    }

    h4 {
      font-size: 36px;
      font-weight: 700;
      color: #fff;
      margin-top: 14px;

      @include for-phone-only {
        font-size: 24px;
        margin-bottom: 5px;
      }
    } 

    .image {
      width: 100%;
      display: block;
      padding-top: 90%;
      position: relative;

      video {
        display: none;
      }

      img { 
        position: absolute;
        top:0;
        width: 100%;
      }
    }

    p {
      color: #fff;
      font-size: 18px;
      
      @include for-phone-only {
        font-size: 16px;
        font-weight: 200;
        line-height: 26px;
      }
    }

    span.link {
      text-decoration: none;
      display: inline-block;
      color: $link-color;
      font-size: 18px;
      padding: 15px 0;

      @include for-phone-only {
        font-size: 14px;
        padding: 10px 0;
      }

      .icon {
        position: relative;
        left: 4px;
        top: 3px;
        width: 20px;
        height: 20px;
        display: inline-block;
        overflow: hidden;

        @include for-phone-only {
          top: 6px;
        }
      }
    }
  }
}

.testimonial-container {
  margin-top: 100px;
  padding: 100px 0;
  background-color: #474161;

  @include for-phone-only {
    margin-top: 40px;
    padding: 50px 0;
    background-color: #2a263f;
  }

  h3 {
    font-size: 56px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 64px;

    @include for-phone-only {
      font-size: 36px;
      margin-bottom: 10px;
      padding: 0 24px;
      text-align: left;
    }
  }

  .testimonial-carousel {
    @include for-phone-only {
      width: 100%;
      overflow-x: scroll;
    }
  }

  ul.testimonials {
    list-style: none;
    width: 1300px;
    margin: auto;
    display: flex;
    gap: 2%;

    @include for-phone-only {
      width: auto;
      gap: 0;
      width: 972px;
      padding: 0 12px;
    }
    
    li {
      width: 31.33%;
      display: block;
      background-color: #585370;
      border-radius: 4px;

      @include for-phone-only {
        width: 300px;
        background-color: #3e3b51;
        margin: 12px;
        margin-bottom: 10px;
      }
        
      .content {
        height: 350px;
        padding: 50px;
        color: #fff;
        font-size: 24px;
        display: flex;
        // align-items: center;
        justify-content: center;
        text-align: center;

        @include for-phone-only {
          font-size: 18px;
          height: 200px;
          padding: 20px;
          font-size: 16px;
        }
      }

      .person {
        font-size: 18px;
        color: #fff;

        @include for-phone-only {
          font-size: 14px;
        }

        .info {
          align-items: center;
          justify-content: center;
          padding-bottom: 50px;

          @include for-phone-only {
            padding-bottom: 30px;
          }
        }

        .image {
          width: 105px;
          // height: 90px;
          // background-color: #fff;
          margin: auto;
          margin-bottom: 10px;

          @include for-phone-only { 
            width: 80px;
          }


          img {
            width: 100%;
          }
        }

        .info > p {
          text-align: center;
          // display: inline-block;
        }

        .name {
          font-weight: 700;
        }

        .position {
          color: #ABA9B7;
        }
      }
    }
  }
}

.footer {
  background-color: #121020;
  color: #fff;
  padding: 125px 0;
  font-weight: 200;

  @include for-phone-only {
    padding: 40px 20px;
    text-align: center;
  }

  p {
    // margin-bottom: 10px;
    font-size: 18px;

    @include for-phone-only {
      margin-bottom: 12px;
    }
  }

  h1 {
    font-size: 56px;
    font-weight: 700;
    margin: 0;
    padding: 0;

    a {
      text-decoration: none;
      color: inherit;
      transition: 0.3s;

      &:hover {
        color: $link-color; 
      }
    }
    
    // margin-bottom: 10px;
    @include for-phone-only {
      font-size: 20px;
      margin-bottom: 12px;
    }
  }

  ul.social-links {
    list-style: none;
    margin-top: 30px;
    display: flex;

    @include for-phone-only {
      justify-content: center;
      margin-top: 20px;

      a {
          display: block;
      }
    }

    li {
      list-style: none;
      transition: 0.3s;
      margin-right: 20px;

      &:last-child {
          margin-right: 0px;
      }

      svg {
        transition: 0.3s;

        @include for-phone-only {
          width: 50px;
        }
      }

      &:hover {

        circle {
          fill: $link-color;
        }

        transform: translate(0px, -10px);
      }
    }
  }
}

.about-section {

  @include for-phone-only {
    padding-top: 100px;
  }

  .paragraph-content {
    display: block;
    
    @include for-phone-only {
      display: none;
    }
  }

  .paragraph-content-mobile {
    display: none;
    padding: 20px;
    font-size: 18px;
    font-weight: 200;
    
    @include for-phone-only {
      display: inline-block;
      padding: 12px 24px;
      padding-top: 5px;
      line-height: 32px;
    }
  }

  a.resume-link {
      text-decoration: none;
      display: inline-block;
      color: $link-color;
      font-size: 18px;
      font-weight: 400;
      padding: 15px 0;
      transition: 0.3s;

      @include for-phone-only {
        font-size: 14px;
      }
  
      .icon {
        position: relative;
        left: 4px;
        top: 3px;
        width: 20px;
        height: 20px;
        display: inline-block;
        overflow: hidden;
        transition: 0.3s;
        
        @include for-phone-only {
          top: 6px;
        }
      }

      &:hover {
        color: #CA0A3A;

        .icon {
          left: 10px;
        }
      }
  }
  
  color: #fff;
  font-size: 18px;
  padding-bottom: 100px;

  @include for-phone-only {
    padding-bottom: 30px;
  }

  .hero-section {
    .content {
      width: 100%;
    }
  }

  .about-content {
    display: flex;

    .image {
      width: 500px;

      @include for-phone-only {
        width: auto;
        margin: 0px;
        margin-bottom: 40px;
      }

      img {
        width: 500px;
        border-radius: 4px;
        @include for-phone-only {
          width: 100%;
        }
      }
    }

    margin-top: 64px;
    display: flex;
    gap: 40px;
    font-size: 24px;
    font-weight: 200;
    line-height: 40px;

    @include for-phone-only {
      margin-top: 10px;
      flex-direction: column;
      font-size: 16px;
      padding: 24px;
      line-height: 26px;
      gap: 0px;
    }

    a {
      color: $link-color;
      font-weight: 700;
      font-size: 24px;
      transition: 0.3s;
      text-decoration: none;

      @include for-phone-only {
        font-size: 16px;
      }

      &:hover {
        color: #CA0A3A;
      }
    }
  }

  .section {
    margin-top: 100px;
    font-size: 24px;
    font-weight: 200;

    &.fun-section {

      @include for-phone-only {
        background-color: #28263f;
      }
    }

    @include for-phone-only {
      padding: 40px 24px;
      margin-top: 0px;
      border-top: 1px solid #424242;

      &.fun-section {
        background-color: #28263f;
        border-top: none;
      }
    }

    ul.photos {
      display: flex;
      flex-wrap: wrap;
      gap: 3%;
      margin-top: 80px;
    
      @include for-phone-only {
        gap: 2%;
        margin-top: 40px;
        justify-content: space-between;
      }
      
      li {
        width: 31.33%;
    
        @include for-phone-only {
          width: 48%;
          margin-bottom: 5px;
        }
    
        img {
          width: 100%;
        }
      }
    }

    p {
      line-height: 40px;

      @include for-phone-only {
        font-size: 16px;
        line-height: 26px;
      }
    }

    h3 {
      font-size: 36px;
      margin-bottom: 40px;

      @include for-phone-only {
        font-size: 24px;
        margin-bottom: 20px;
      }
    }

    li {
      list-style: none;
      font-size: 18px;
      font-weight: 200;
      margin-bottom: 40px;

      @include for-phone-only {
        font-size: 16px;
        margin-bottom: 20px;
      }


       span {
         font-size: 24px;
         font-weight: 700;
         margin-right: 10px;

         @include for-phone-only {
           font-size: 18px;
           display: block;
         }

         .date {
           font-size: 18px;
           font-weight: 400;

           @include for-phone-only {
            font-size: 14px;
            display: inline;
           }
         }
       }
    }
  }
}